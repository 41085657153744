<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="수질오염방지시설 고장 유무 및 특기사항"
        tableId="grid"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :usePaging="false"
        :hideBottom="false"
        :columnSetting="false"
        :filtering="false"
        :isExcelDown="false"
        :editable="editable"
        :gridHeightAuto="param.isFullScreen"
        selection="multiple"
        rowKey="envWaterMstPreventiveBreakdownId"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="추가" icon="add" v-if="!param.isFullScreen && editable" @btnClicked="addPreventive"/>
            <c-btn label="삭제" icon="remove" v-if="!param.isFullScreen && editable" @btnClicked="removePreventive" />
            <c-btn label="저장" icon="save" v-if="!param.isFullScreen && editable" @btnClicked="saveData"/>
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'daily-report-09',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: null,
        envWaterDailyResultId: '',
        isFullScreen: false,
        dailyResultDt: '',
        approvalStatusCd: null,
        approvalTarget: false,
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '60%',
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'envWaterMstPreventiveName',
            field: 'envWaterMstPreventiveName',
            label: '고장시설명',
            align: 'center',
            sortable: false,
          },
          {
            name: 'breakdownStartDt',
            field: 'breakdownStartDt',
            label: '고장시작일',
            align: 'center',
            type: 'date',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'breakdownEndDt',
            field: 'breakdownEndDt',
            label: '고장종료일',
            align: 'center',
            type: 'date',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'breakdownStatus',
            field: 'breakdownStatus',
            label: '고장상태',
            align: 'center',
            type: 'text',
            style: 'width:20%',
            sortable: true,
          },
          {
            name: 'breakdownAction',
            field: 'breakdownAction',
            label: '조치상태',
            align: 'center',
            type: 'text',
            style: 'width:20%',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '특기사항',
            align: 'center',
            type: 'text',
            style: 'width:20%',
            sortable: false,
          },
        ],
        height: '600px',
        data: [],
      },
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  computed: {
    editable() {
      if (this.param.approvalTarget) {
        return true;
      } else if (this.param.approvalStatusCd !== null) {
        return false;
      } else {
        return this.$route.meta.editable
      }
    },
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.env.water.mst.preventive.breakdown.list.url;
      this.saveUrl = transactionConfig.env.water.mst.preventive.breakdown.save.url;
      this.deleteUrl = transactionConfig.env.water.mst.preventive.breakdown.deletes.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {envWaterDailyResultId: this.param.envWaterDailyResultId, plantCd: this.param.plantCd, dailyResultDt: this.param.dailyResultDt};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    saveData() {
      let saveData = this.grid.data.filter( x => {
        return x.editFlag == 'U' || x.editFlag == 'C'
      });
      this.$_.forEach(saveData, item => {
        item.regUserId = this.$store.getters.user.userId;
        item.chgUserId = this.$store.getters.user.userId;
      });
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              this.getList();
              window.getApp.$emit('ALERT', {
                title: '안내', 
                message: '저장되었습니다.', 
                type: 'success', // success / info / warning / error
              });
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '변경된 데이터가 없습니다.',
          type: 'info', // success / info / warning / error
        });
      }
    },
    addPreventive() {
      this.popupOptions.title = '수질방지시설 검색'; // 수질방지시설 검색
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/water/waterPreventivePop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          this.grid.data.push({
            envWaterMstPreventiveName: _item.envWaterMstPreventiveName,  // 수질방지시설명
            envWaterMstPreventiveId: _item.envWaterMstPreventiveId,  // 수질방지시설 일련번호
            envWaterMstPreventiveBreakdownId: uid(),  // 수질방지시설 고장이력 일련번호
            breakdownStartDt: this.param.dailyResultDt,  // 고장기간 시작일
            breakdownEndDt: this.param.dailyResultDt,  // 고장기간 종료일
            breakdownStatus: '',  // 고장상태
            breakdownAction: '',  // 조치사항
            remark: '',  // 특기사항
            bactionDt: '',  // 조치일
            chgUserId: this.$store.getters.user.userId,
            regUserId: this.$store.getters.user.userId,
            editFlag: 'C'
          })
        })
      }
    },
    removePreventive() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    }
  }
};
</script>
